import React, { useState, useEffect } from "react";
import { Panel, PanelBody } from "./../../../components/panel/panel.jsx";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import NavigationButton from "../../../components/constantComponent/button/NavigationButton";
import { ApiKey, ApiUrl } from "../../../util/Constant";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { stringIsNullOrEmpty, createFormBody } from "../../../util/Util";
import { LanguageKey } from "../../../util/Constant";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../../redux/AppAction";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";

/// <summary>
/// Author : -, -
/// </summary>

const BankAccountDetail = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  let _history = useHistory();
  const _location = useLocation();
  const _dispatch = useDispatch();
  const [bankOption, setBankOption] = useState([]);
  const { register, unregister, handleSubmit, errors, setValue, control } = useForm();

  const [bankId, setBankId] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankAccountHolderName, setBankAccountHolderName] = useState("");
  const [branch, setBranch] = useState("");
  const [province, setProvince] = useState("");
  const [rank, setRank] = useState("");
  const [vipOption, setVipOption] = useState([]);

  const [minimumDeposit, setMinimumDeposit] = useState("");
  const [maximumDeposit, setMaximumDeposit] = useState("");
  const [minimumWithdrawal, setMinimumWithdrawal] = useState("");
  const [maximumWithdrawal, setMaximumWithdrawal] = useState("");
  const [bankAccountId, setBankAccountId] = useState("");
  const [depositStatus, setDepositStatus] = useState(true);
  const [withdrawalStatus, setWithdrawalStatus] = useState(true);
  const [accountStatus, setAccountStatus] = useState(true);
  const [instantDepositAccountStatus, setInstantDepositAccountStatus] = useState(false);
  const [excelId, setExcelId] = useState("");
  const [sheetName, setSheetName] = useState("");

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    register({ name: "bankId" }, { required: "PLEASE_SELECT_BANK" });
    init();
    getVipLevel();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  async function init() {
    if (_location.state) {
      var apiUrl = ApiUrl._API_GET_BANK_ACCOUNT_DETAIL;
      apiUrl += "?bankAccountId=" + _location.state.id;
      await fetch(apiUrl, {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setBankId(responseJson[ApiKey._API_DATA_KEY]["bankId"]);
            setBankAccountNumber(
              responseJson[ApiKey._API_DATA_KEY]["accountNumber"]
            );
            setBankAccountHolderName(
              responseJson[ApiKey._API_DATA_KEY]["accountHolderName"]
            );
            setBranch(responseJson[ApiKey._API_DATA_KEY]["branch"]);
            setProvince(responseJson[ApiKey._API_DATA_KEY]["province"]);
            setRank(responseJson[ApiKey._API_DATA_KEY]["rank"]);
            setMinimumDeposit(
              responseJson[ApiKey._API_DATA_KEY]["minimumDeposit"]
            );
            setMaximumDeposit(
              responseJson[ApiKey._API_DATA_KEY]["maximumDeposit"]
            );
            setMinimumWithdrawal(
              responseJson[ApiKey._API_DATA_KEY]["minimumWithdrawal"]
            );
            setMaximumWithdrawal(
              responseJson[ApiKey._API_DATA_KEY]["maximumWithdrawal"]
            );
            setBankAccountId(responseJson[ApiKey._API_DATA_KEY]["id"]);
            setDepositStatus(
              responseJson[ApiKey._API_DATA_KEY]["depositStatus"]
            );
            setWithdrawalStatus(
              responseJson[ApiKey._API_DATA_KEY]["withdrawalStatus"]
            );
            setAccountStatus(
              responseJson[ApiKey._API_DATA_KEY]["accountStatus"]
            );
            setInstantDepositAccountStatus(
              responseJson[ApiKey._API_DATA_KEY]["instantDepositAccountStatus"]
            );
            setExcelId(
              responseJson[ApiKey._API_DATA_KEY]["excelId"]
            );
            setSheetName(
              responseJson[ApiKey._API_DATA_KEY]["sheetName"]
            );
            setValue("vipLevel", responseJson[ApiKey._API_DATA_KEY]["vipLevel"]);
            unregister("bankId");
          }
        });
    }

    fetch(ApiUrl._API_GET_USER_BANK + "?all=true", {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const bankList = [];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (bank) {
            bankList.push({ label: bank.bankCode, value: bank.id });
          });

          setBankOption(bankList);
        }
      });
  }

  const getVipLevel = async () => {
    let vipList = [];
    fetch(ApiUrl._API_GET_USER_VIP, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          vipList.push({ label: "All", value: -1 });
          responseJson[ApiKey._API_DATA_KEY].forEach(function (vipLevel) {
            vipList.push({ label: vipLevel.name, value: vipLevel.id });
          });
          setValue("vipLevel", vipList[0].value);
          setVipOption(vipList);
        }
      });
  }

  /// <summary>
  /// Author : -
  /// </summary>
  const submitForm = async (data, e) => {
    let params = {
      accountNumber: data.bankAccountNumber,
      accountHolderName: data.bankAccountHolderName,
      branch: data.branch,
      province: data.province,
      rank: data.rank,
      vipLevel: data.vipLevel,
      minimumDeposit: data.minimumDeposit,
      maximumDeposit: data.maximumDeposit,
      minimumWithdrawal: data.minimumWithdrawal,
      maximumWithdrawal: data.maximumWithdrawal,
      depositStatus: data.depositStatus,
      withdrawalStatus: data.withdrawalStatus,
      accountStatus: data.accountStatus,
      instantDepositAccountStatus: data.instantDepositAccountStatus,
      excelId: data.excelId,
      sheetName: data.sheetName,
      bankId: bankId,
    };

    if (!stringIsNullOrEmpty(bankAccountId)) {
        params["id"] = bankAccountId;
    }

    let formBody = createFormBody(params);

    _dispatch(setBusy());
    fetch(ApiUrl._API_CREATE_OR_UPDATE_BANK_ACCOUNT_DETAIL, {
      method: ApiKey._API_POST,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setBankAccountId(responseJson[ApiKey._API_DATA_KEY]["id"]);
        }
        _dispatch(
          showMessage(
            responseJson[ApiKey._API_SUCCESS_KEY],
            t(responseJson[ApiKey._API_MESSAGE_KEY])
          )
        );
      });

    if (!stringIsNullOrEmpty(bankAccountId)) {
      _dispatch(setSaveAdminLog("EDIT_BO_BANK_ACCOUNT", _history.location.pathname, params));
    } else {
      _dispatch(setSaveAdminLog("CREATE_BO_BANK_ACCOUNT", _history.location.pathname, params));
    }
  };

  return (
    <div>
      <h1 className="page-header">
        {bankId ? t("EDIT_BANK_ACCOUNT") : t("ADD_NEW_BANK_ACCOUNT")}
        <NavigationButton history={_history} />
      </h1>
      <div className="row">
        <div className="col-xl-12">
          <Panel>
            <PanelBody>
              <form onSubmit={handleSubmit(submitForm)}>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card text-truncate mb-3">
                          <div className="card-header">
                            <b>{t("BANK_ACCOUNT_INFORMATION")}</b>
                          </div>
                          <div className="card-body">
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("AVAILABLE_BANK")}</b>
                              </label>
                              <div className="col-md-11">
                                <Select
                                  name="bankId"
                                  options={bankOption}
                                  placeholder={
                                    bankOption.filter(
                                      (option) => option.value == bankId
                                    )[0] !== undefined
                                      ? bankOption.filter(
                                          (option) => option.value == bankId
                                        )[0].label
                                      : ""
                                  }
                                  value={bankOption.filter(
                                    (option) => option.value == bankId
                                  )}
                                  onChange={(e) => {
                                    unregister("bankId");
                                    setBankId(e.value);
                                  }}
                                />
                                {errors.bankId && (
                                  <div className="invalid-feedback">
                                    {t(errors.bankId.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("BANK_ACCOUNT_NUMBER")}</b>
                              </label>
                              <div className="col-md-11">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={t(
                                    "PLEASE_ENTER_BANK_ACCOUNT_NUMBER"
                                  )}
                                  name="bankAccountNumber"
                                  defaultValue={bankAccountNumber}
                                  ref={register({
                                    required:
                                      "PLEASE_ENTER_BANK_ACCOUNT_NUMBER",
                                  })}
                                />
                                {errors.bankAccountNumber && (
                                  <div className="invalid-feedback">
                                    {t(errors.bankAccountNumber.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("BANK_ACCOUNT_HOLDER_NAME")}</b>
                              </label>
                              <div className="col-md-11">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={t(
                                    "PLEASE_ENTER_BANK_ACCOUNT_HOLDER_NAME"
                                  )}
                                  defaultValue={bankAccountHolderName}
                                  name="bankAccountHolderName"
                                  ref={register({
                                    required:
                                      "PLEASE_ENTER_BANK_ACCOUNT_HOLDER_NAME",
                                  })}
                                />
                                {errors.bankAccountHolderName && (
                                  <div className="invalid-feedback">
                                    {t(errors.bankAccountHolderName.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("BRANCH")}</b>
                              </label>
                              <div className="col-md-11">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="branch"
                                  defaultValue={branch}
                                  placeholder={t("PLEASE_ENTER_BRANCH")}
                                  ref={register({
                                    required: "PLEASE_ENTER_BRANCH",
                                  })}
                                />
                                {errors.branch && (
                                  <div className="invalid-feedback">
                                    {t(errors.branch.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("PROVINCE")}</b>
                              </label>
                              <div className="col-md-11">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={t("PLEASE_ENTER_PROVINCE")}
                                  name="province"
                                  defaultValue={province}
                                  ref={register({
                                    required: "PLEASE_ENTER_PROVINCE",
                                  })}
                                />
                                {errors.province && (
                                  <div className="invalid-feedback">
                                    {t(errors.province.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("VIP_LEVEL")}</b>
                              </label>
                              <div className="col-md-11">
                                <Controller
                                  control={control}
                                  name="vipLevel"
                                  render={({ onChange, value }) => (
                                    <Select options={vipOption}
                                      placeholder={(vipOption.filter(option => option.value == value)[0] !== undefined) ? (vipOption.filter(option => option.value == value)[0].label) : ""}
                                      value={vipOption.filter(option => option.value == value)}
                                      onChange={(e) => {
                                        onChange(e.value);
                                      }} />
                                  )}
                                  rules={{ required: "PLEASE_SELECT_VIP_LEVEL" }}
                                />
                                {errors.vipLevel && <div className="invalid-feedback">{t(errors.vipLevel.message)}</div>}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("RANK")}</b>
                              </label>
                              <div className="col-md-11">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder={t("PLEASE_ENTER_RANK")}
                                  name="rank"
                                  defaultValue={rank}
                                  ref={register({
                                    required: "PLEASE_ENTER_RANK",
                                    min: {
                                      value: 0,
                                      message:
                                        "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                    },
                                    max: {
                                      value: 100,
                                      message:
                                        "PLEASE_ENTER_VALUE_NOT_MORE_THAN_100",
                                    },
                                  })}
                                />
                                {errors.rank && (
                                  <div className="invalid-feedback">
                                    {t(errors.rank.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card text-truncate mb-3">
                          <div className="card-header">
                            <b>
                              {t("BANK_AMOUNT_LIMIT")} ({t("DEPOSIT")} /{" "}
                              {t("WITHDRAWAL")})
                            </b>
                          </div>
                          <div className="card-body">
                            <div className="form-group row m-b-15">
                              <label className="col-md-3 col-form-label">
                                <b>{t("MINIMUM_DEPOSIT")}</b>
                              </label>
                              <div className="col-md-11">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="minimumDeposit"
                                  defaultValue={minimumDeposit}
                                  ref={register({
                                    required: "PLEASE_ENTER_MINIMUM_DEPOSIT",
                                    min: {
                                      value: 0,
                                      message:
                                        "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                    },
                                  })}
                                />
                                {errors.minimumDeposit && (
                                  <div className="invalid-feedback">
                                    {t(errors.minimumDeposit.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-3 col-form-label">
                                <b>{t("MAXIMUM_DEPOSIT")}</b>
                              </label>
                              <div className="col-md-11">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="maximumDeposit"
                                  defaultValue={maximumDeposit}
                                  ref={register({
                                    required: "PLEASE_ENTER_MAXIMUM_DEPOSIT",
                                    min: {
                                      value: 0,
                                      message:
                                        "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                    },
                                  })}
                                />
                                {errors.maximumDeposit && (
                                  <div className="invalid-feedback">
                                    {t(errors.maximumDeposit.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-3 col-form-label">
                                <b>{t("MINIMUM_WITHDRAWAL")}</b>
                              </label>
                              <div className="col-md-11">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="minimumWithdrawal"
                                  defaultValue={minimumWithdrawal}
                                  ref={register({
                                    required: "PLEASE_ENTER_MINIMUM_WITHDRAWAL",
                                    min: {
                                      value: 0,
                                      message:
                                        "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                    },
                                  })}
                                />
                                {errors.minimumWithdrawal && (
                                  <div className="invalid-feedback">
                                    {t(errors.minimumWithdrawal.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-3 col-form-label">
                                <b>{t("MAXIMUM_WITHDRAWAL")}</b>
                              </label>
                              <div className="col-md-11">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="maximumWithdrawal"
                                  defaultValue={maximumWithdrawal}
                                  ref={register({
                                    required: "PLEASE_ENTER_MAXIMUM_WITHDRAWAL",
                                    min: {
                                      value: 0,
                                      message:
                                        "PLEASE_ENTER_VALUE_NOT_LESS_THAN_ZERO",
                                    },
                                  })}
                                />
                                {errors.maximumWithdrawal && (
                                  <div className="invalid-feedback">
                                    {t(errors.maximumWithdrawal.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card text-truncate mb-3">
                          <div className="card-header">
                            <b>{t("BANK_ACCOUNT_STATUS")}</b>
                          </div>
                          <div className="card-body">
                            <div className="form-group row m-b-15">
                              <label className="col-md-8 col-form-label">
                                <b>{t("DEPOSIT_STATUS")}</b>
                              </label>
                              <div className="col-md-4">
                                <div className="switcher">
                                  <input
                                    type="checkbox"
                                    name="depositStatus"
                                    id="depositStatus"
                                    onChange={(e) =>
                                      setDepositStatus(e.target.checked)
                                    }
                                    value={true}
                                    checked={depositStatus}
                                    ref={register}
                                  />
                                  <label htmlFor="depositStatus"></label>
                                </div>
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-8 col-form-label">
                                <b>{t("WITHDRAWAL_STATUS")}</b>
                              </label>
                              <div className="col-md-4">
                                <div className="switcher">
                                  <input
                                    type="checkbox"
                                    name="withdrawalStatus"
                                    id="withdrawalStatus"
                                    onChange={(e) =>
                                      setWithdrawalStatus(e.target.checked)
                                    }
                                    value={true}
                                    checked={withdrawalStatus}
                                    ref={register}
                                  />
                                  <label htmlFor="withdrawalStatus"></label>
                                </div>
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-8 col-form-label">
                                <b>{t("ACCOUNT_STATUS")}</b>
                              </label>
                              <div className="col-md-4">
                                <div className="switcher">
                                  <input
                                    type="checkbox"
                                    name="accountStatus"
                                    id="accountStatus"
                                    onChange={(e) =>
                                      setAccountStatus(e.target.checked)
                                    }
                                    value={true}
                                    checked={accountStatus}
                                    ref={register}
                                  />
                                  <label htmlFor="accountStatus"></label>
                                </div>
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-8 col-form-label">
                                <b>{t("INSTANT_DEPOSIT_ACCOUNT_STATUS")}</b>
                              </label>
                              <div className="col-md-4">
                                <div className="switcher">
                                  <input
                                    type="checkbox"
                                    name="instantDepositAccountStatus"
                                    id="instantDepositAccountStatus"
                                    onChange={(e) =>
                                      setInstantDepositAccountStatus(e.target.checked)
                                    }
                                    value={true}
                                    checked={instantDepositAccountStatus}
                                    ref={register}
                                  />
                                  <label htmlFor="instantDepositAccountStatus"></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="card text-truncate mb-3">
                          <div className="card-header">
                            <b>{t("EXCEL_LINK")}</b>
                          </div>
                          <div className="card-body">
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("EXCEL_ID")}</b>
                              </label>
                              <div className="col-md-11">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="excelId"
                                  defaultValue={excelId}
                                  placeholder={t("PLEASE_ENTER_EXCEL_ID")}
                                  ref={register({
                                    required: "PLEASE_ENTER_EXCEL_ID",
                                  })}
                                />
                                {errors.excelId && (
                                  <div className="invalid-feedback">
                                    {t(errors.excelId.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-group row m-b-15">
                              <label className="col-md-4 col-form-label">
                                <b>{t("SHEET_NAME")}</b>
                              </label>
                              <div className="col-md-11">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="sheetName"
                                  defaultValue={sheetName}
                                  placeholder={t("PLEASE_ENTER_SHEET_NAME")}
                                  ref={register({
                                    required: "PLEASE_ENTER_SHEET_NAME",
                                  })}
                                />
                                {errors.sheetName && (
                                  <div className="invalid-feedback">
                                    {t(errors.sheetName.message)}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        {t("SUBMIT")}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </PanelBody>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default BankAccountDetail;
